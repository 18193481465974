import { FieldType } from './FieldType';
import { IAEMConnectionOptions, IAEMMetadata } from './AEMConnection';
import { IWFConnectionOptions } from './WFConnection';

export interface IConnectionObjectMetadata<T> {
  name: string;
  displayName: string;
  lazy: boolean;
  value: T;
}

export interface IConnection {
  name: string;
  displayName: string;
  objects: Array<{
    name: string;
    displayName: string;
    metadata: IAEMMetadata | null;
  }>;
}

export interface IConnectionField {
  type: FieldType;
  name: string;
  displayName: string;
  isPrimary: boolean;
  groupName: string;
}

export interface IConnectionFilter {
  name: string;
  displayName: string;
  type: FieldType;
  options?: Array<{ name: string; displayName: string }>;
}

export enum ConnectionType {
  WORKFRONT = 'workfront',
  AEM = 'aem',
}

export type ConnectionOptions = IWFConnectionOptions | IAEMConnectionOptions;
