import { getPublicToken } from '@wf-mfe-maestro/navigation';

import { MaestroError } from '../errors';
import { RequestMethod } from '../types/RequestMethod';
import { requestUrl } from '../utils/requestUrl';
import { responseHandler } from '../utils/responseHandler';
import { getIntermediaryHeader } from '../utils/getIntermediaryHeaders';
import { AbstractApiClient, ApiClientFetchOptions } from './AbstractApiClient';

const handleErrorResponse = (response: Response) =>
  response
    .json()
    .catch((error) => {
      throw new Error(response.statusText, { cause: error });
    })
    .then((errorBody) => {
      if (errorBody.type) {
        throw new MaestroError(errorBody.type, errorBody.report?.messageArguments);
      }
      throw new Error(response.statusText);
    });

export default class WorkfrontApiClient extends AbstractApiClient {
  constructor(
    /**
     * @param baseURL The base URL of the API.
     * e.g. https://unity.devtest.workfront-dev.com or /
     */
    protected baseURL: string,
    /**
     * @param endpointPrefix The prefix of the endpoint.
     * e.g. /api/v2
     * will be skipped if the request endpoint starts with /
     */
    protected endpointPrefix: string
  ) {
    super();
  }

  async fetch<ResponseType>(
    endpoint: string,
    method: RequestMethod,
    body?: unknown,
    fetchOptions?: ApiClientFetchOptions
  ): Promise<ResponseType> {
    if (fetchOptions && ('body' in fetchOptions || 'method' in fetchOptions)) {
      throw Error(`The "fetchOptions" parameter cannot have properties "body" and "method".`);
    }
    // eslint-disable-next-line no-restricted-syntax
    return System.import('@wf-mfe/api').then(
      ({ wfetch, getAuthHeaders, JSON_CONTENT_TYPE_HEADER }) => {
        const publicTokenValue = getPublicToken();
        const publicTokenHeader =
          publicTokenValue !== '' ? { ['x-public-token']: publicTokenValue } : {};

        const headers = {
          'x-request-id': this.generateRequestId(),
          ...getIntermediaryHeader(),
          ...getAuthHeaders(),
          ...fetchOptions?.headers,
          ...publicTokenHeader,
        };
        if (!(body instanceof FormData) && !headers['Content-Type']) {
          Object.assign(headers, JSON_CONTENT_TYPE_HEADER);
        }

        return wfetch(
          this.getRequestUrl(publicTokenValue !== '' ? `public/${endpoint}` : endpoint),
          {
            ...fetchOptions,
            method,
            headers,
            body: body ? (body instanceof FormData ? body : JSON.stringify(body)) : body,
          },
          {
            handleOkResponse: responseHandler,
            handleErrorResponse,
            preventAutoRedirect: publicTokenValue !== '',
          }
        );
      }
    );
  }

  /**
   * builds the request url
   * Gives ability to override this method in child classes depending on endpoint
   */
  protected getRequestUrl(endpoint: string) {
    return requestUrl(this.baseURL, this.endpointPrefix, endpoint);
  }
}

export { handleErrorResponse };
