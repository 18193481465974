import { IConnection, IConnectionField, IConnectionFilter, IRecord } from '../types';
import { AbstractApiClient } from './AbstractApiClient';

export default class ConnectionAPI {
  protected client: AbstractApiClient;
  protected ENDPOINT = 'connections';
  protected METADATA_ENDPOINT = 'connections/metadata';

  constructor(client: AbstractApiClient) {
    this.client = client;
  }

  getAll(): Promise<IConnection[]> {
    return this.client.get<IConnection[]>(`${this.ENDPOINT}`);
  }

  getConnectionObjectMetadata<T = unknown>(
    connectionName: string,
    objectName: string,
    metadataName: string
  ) {
    return this.client.get<T>(
      `${this.METADATA_ENDPOINT}?${new URLSearchParams({
        connectionName,
        objectName,
        metadataName,
      }).toString()}`
    );
  }

  getFilters(connectionName: string, objectName: string): Promise<IConnectionFilter[]> {
    return this.client.get<IConnectionFilter[]>(
      `${this.ENDPOINT}/filters?${new URLSearchParams({ connectionName, objectName })}`
    );
  }

  getFields(connection: string, object: string): Promise<IConnectionField[]> {
    return this.client.get<IConnectionField[]>(
      `${this.ENDPOINT}/fields?${new URLSearchParams({ connection, object })}`
    );
  }

  getCreateRecordPermission(connectionName: string, objectName: string): Promise<boolean> {
    return this.client.get<boolean>(
      `${this.ENDPOINT}/has-access?connectionName=${connectionName}&objectName=${objectName}&actionTypeName=ADD`
    );
  }

  createExternalRecord(body: { recordTypeId: string; name: string }): Promise<IRecord> {
    return this.client.post(`${this.ENDPOINT}/create`, body);
  }
}
