import excApp from '@adobe/exc-app';
import user from '@adobe/exc-app/user';
import { getIsInGenStudio, getIsMaestroStandalone } from '@wf-mfe-maestro/navigation';

import MaestroAPI from './api';
import StandAloneApiClient from './api/StandAloneApiClient';
import WorkfrontApiClient from './api/WorkfrontApiClient';
import { envMappingToAdobeIOUrl } from './constants';
import { isValidEnvironment } from './utils/environmentChecks';

/**
 * TODO:
 * dispatch event about request status
 */

export const getAuth = async () =>
  Promise.all([user.get('imsToken'), user.get('imsOrg'), user.get('imsProfile')]).then(
    ([token, org, profile]) => ({ token, org, profile })
  );

let client;

if (getIsMaestroStandalone()) {
  const runtime = excApp();
  const env = runtime.lastConfigurationPayload?.environment;
  isValidEnvironment(env);
  client = new StandAloneApiClient(
    envMappingToAdobeIOUrl[env],
    '/planning/attachments',
    'v1',
    // apiKeys used here are configured in adobe.io
    getIsInGenStudio() ? 'maestro-genstudio' : 'maestro',
    getAuth
  );
} else if (process.env.USE_MOCK_BACKEND_URL) {
  // avoid loading the class itself, if USE_MOCK_BACKEND is not set
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const { MockApiClient } = require('./api/MockApiClient');
  client = new MockApiClient('', 'maestro/api/internal', process.env.USE_MOCK_BACKEND_URL);
} else {
  client = new WorkfrontApiClient('', 'maestro/api/internal');
}

export const api = new MaestroAPI(client);

export * from './types';
export * from './emitter';
export * from './enums';
export * from './errors';
export { MaestroStorage } from './utils/MaestroStorage';
export {
  generateIntermediaryId,
  getIntermediaryHeader,
  getGatewayHeader,
  intermediaryId,
} from './utils/getIntermediaryHeaders';
