import { ILocalizableMessage, MaestroErrorCode } from '../types/Error';
import { getMessageByCode } from '../utils/errorMessageKeys';
import { log } from '../utils/logger';

export class MaestroError extends Error {
  public name = 'MaestroError';

  static genericMessageKey = 'error.generic';
  static genericMessageFallback = 'Something went wrong. Please try again.';

  public localizableMessage: ILocalizableMessage;
  private errorCode: MaestroErrorCode;

  constructor(code: MaestroErrorCode, args?: Array<string | number>) {
    let errorMessage = getMessageByCode(code, args);
    if (!errorMessage) {
      errorMessage = {
        key: MaestroError.genericMessageKey,
        fallback: MaestroError.genericMessageFallback,
        args: [],
      };
      log.warn(`Unable to find error message key for code ${code}`);
    }

    super(errorMessage.fallback);

    this.localizableMessage = errorMessage;
    this.errorCode = code;
  }

  public isGenericError(): boolean {
    return this.localizableMessage.key === MaestroError.genericMessageKey;
  }

  public getErrorCode(): MaestroErrorCode {
    return this.errorCode;
  }
}
