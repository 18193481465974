import { AccessPermission } from './AccessPermission';

export enum ViewType {
  TABLE = 'table',
  TIMELINE = 'timeline',
  CALENDAR = 'calendar',
  LIST = 'list',
  GALLERY = 'gallery',
}

export enum TimelineScale {
  Year = 'YEAR',
  Quarter = 'QUARTER',
  Month = 'MONTH',
}

export enum SortingDirection {
  Asc = 'asc',
  Desc = 'desc',
}

export interface IFieldSorting {
  fieldId: string;
  direction: SortingDirection;
}

export interface IFilterValues {
  [key: string]: {
    [key: string]: string | string[];
  };
}

export interface IFieldFiltering {
  [key: string]: IFilterValues[];
}

export enum RecordColoring {
  RECORD_TYPE = 'RECORD_TYPE',
  FIELD = 'FIELD',
  GROUP = 'GROUP',
  NONE = 'NONE',
}

export enum GroupColoring {
  DEFAULT = 'DEFAULT',
  FIELD = 'FIELD',
}

export interface IViewColoring {
  record: {
    selected: RecordColoring;
    fieldId: string | null;
  };
  group: {
    selected: GroupColoring;
  };
}

export interface IBaseView {
  id: string;
  createdBy: string;
  createdAt: string;
  updatedBy: string;
  updatedAt: string;
  name: string;
  type: ViewType;
  filter: IFieldFiltering[];
  grouping: string[] | null;
  sorting: IFieldSorting[] | null;
  recordTypeId: string;
  permission: AccessPermission | null;
  isPublicShared: boolean;
}

interface IBaseMetadata {
  showThumbnail: boolean;
}

export enum TableRowHeight {
  SHORT = 'SHORT',
  COMPACT = 'COMPACT',
  MEDIUM = 'MEDIUM',
  TALL = 'TALL',
}

export interface ITableViewMetadata extends IBaseMetadata {
  type: ViewType.TABLE;
  columnMetadata: IColumnMetadata[];
  rowOrder: string[];
  rowHeight?: TableRowHeight;
}

export interface IListViewMetadata extends IBaseMetadata {
  type: ViewType.LIST;
  columnMetadata: IColumnMetadata[];
}

export interface ITimelineViewMetadata extends IBaseMetadata {
  type: string;
  startDateKey: string;
  endDateKey: string;
  timelineScale: TimelineScale;
  cardFieldIds: string[];
  coloring?: IViewColoring;
}

export interface IGalleryViewMetadata extends IBaseMetadata {
  layout?: string;
  columnMetadata?: ITableViewMetadata['columnMetadata'];
}

export interface IRecordBreakdownNode {
  recordTypeId: string;
  isEnabled: boolean;
  referencingFieldId: string;
  startDateFieldId: string;
  endDateFieldId: string;
}

export type TRecordBreakdownList = IRecordBreakdownNode[];

export interface ITimelineView extends IBaseView {
  metadata: ITimelineViewMetadata | null;
  type: ViewType.TIMELINE;
  recordBreakdownList: TRecordBreakdownList;
}

export interface ICalendarView extends IBaseView {
  metadata: ITimelineViewMetadata | null;
  type: ViewType.CALENDAR;
}

export interface IColumnMetadata {
  width: number;
  fieldId: string;
  //TODO: will be removed after grid MR merge
  visibility?: boolean | null;
}
export interface ITableView extends IBaseView {
  metadata?: ITableViewMetadata | null;
  type: ViewType.TABLE;
}

export interface IListView extends IBaseView {
  metadata?: IListViewMetadata | null;
  type: ViewType.LIST;
}

export interface IGalleryView extends IBaseView {
  metadata?: IGalleryViewMetadata;
  type: ViewType.GALLERY;
}

export type IView = ITableView | ITimelineView | ICalendarView | IListView | IGalleryView;

export type ViewDTO = Pick<IView, 'name' | 'type' | 'recordTypeId'> &
  Partial<Pick<IView, 'metadata'>>;

export interface ITableViewRowOrderDTO {
  rowIds: string[];
  toId?: string;
}

export interface IRecordTypeViewsCount {
  [recordTypeId: string]: number;
}

export enum TimelineMode {
  Standard = 'STANDARD',
  Compact = 'COMPACT',
}
